import './App.css';

import Header from './components/header';
import Main from './components/main';
import Footer from './components/footer'

function App() {
  return (
    <>
      <Header />
      <Main />  
      <Footer />    
    </>
  );
}

export default App;
